import * as React from 'react'

// trigger focus() on ref; applicable for elements without native autoFocus attr
const useAutoFocus = (ref = React.useRef<HTMLElement>(null), enable = true) => {
  React.useEffect(() => {
    if (enable && ref.current) {
      ref.current.focus()
    }
  }, [])

  return ref
}

export default useAutoFocus
