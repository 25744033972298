import * as React from 'react'
import Stylo, { Props as StyloProps } from './Stylo'
import { CmpStyles } from '../providers/style/types'

export type Props = StyloProps & {
  isVertical?: boolean
  expand?: boolean
  disableDefaultStyles?: boolean
  [key: string]: any // accept any properties
}

const defaultStyle: CmpStyles = {
  box: ({ isVertical, expand }: Props) => ({
    ...(isVertical && { flexDirection: 'column' }),
    ...(expand && { flex: 1 }),
  }),
}

const View: React.FC<Props> = ({ style, ...props }) => (
  <Stylo
    {...props}
    style={style ? [defaultStyle.box, style] : defaultStyle.box}
  />
)

export default View
